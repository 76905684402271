.banner {
  width: 100%;
  height: 40rem;
  margin-top: 2rem;

  background: #f3f3f3;
  width: 100%;
  background-image: "../../images/defaultBanner.jpg";
  display: flex;

  @include mobile {
    height: auto;
    flex-direction: column;
  }
}

.banner-desc {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 5rem;
  flex-basis: 50%;

  @include mobile {
    padding: 5rem 0;
  }

  h1 {
    font-size: 4.8rem;
    margin-bottom: 1rem;
    width: 80%;

    @include mobile {
      width: 100%;
    }
  }
}

.banner-img {
  position: relative;
  width: 100%;
  height: 100%;
  flex-basis: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    margin: 0 auto;
    position: relative;
    transform: translateX(5rem);
  }
}

@media (max-width: 479px), screen and (orientation: portrait) {
  .banner-img {
    position: relative;
    width: 100%;
    height: 100%;
    flex-basis: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
      margin: 0 auto;
      position: relative;
      transform: translateX(5rem);
      display: none;
    }
  }

  /* … */
}

.non-portrait {
  display: initial;
}

@media (orientation: portrait) {
  .non-portrait {
    display: none;
  }
}
