.home {
    width: 100%;
}

.home-featured {
    margin: 5rem;
    margin-top: 10rem;
}

.featured {
    width: 100%;
}