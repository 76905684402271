.non-portrait {
  display: initial;
}

.menu {
}

.logo-img {
  max-height: 86px;
}
.menu-img {
  max-height: 43px;
  display: none;
}
.nav-items {
  visibility: initial;
  margin-right: 32vw;
}

@media (max-width: 479px) {
  .non-portrait {
    display: none;
  }
}

.search {
  width: 50vw;
}

@media (orientation: portrait) {
  .logo-img {
    display: none;
  }

  .nav-items {
    visibility: hidden;
    margin: -35px;
  }

  .menu-img {
    display: initial;
  }

  .search {
    margin-right: 3rem;
  }

  .basket-portrait {
    float: right;
    position: relative;
    left: 3.7rem;
    margin-left: -4rem;
  }

  .menu {
    display: flex;
    flex-direction: column;
    right: 4rem;
    position: relative;
  }

  .nonportrait {
    display: none;
  }
  /* … */
}
