.only-portrait {
  display: none;
}

@media (max-width: 479px), screen and (orientation: portrait) {
  .only-portrait {
    display: block;
  }
  /* … */
}
