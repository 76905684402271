.navigation {
  width: 100%;
  height: 10rem;
  background: $background-color;
  display: flex;
  align-items: center;
  padding: 0.5rem 5rem;
  padding-top: 3rem;
  position: absolute;
  top: 0;
  transform: translateY(0);
  z-index: map-get($z-index, navigation);
  box-shadow: none;
  @include bezier-transition(transform, 0.3s, ease);

  @include tablet {
    padding: 0.5rem 2rem;
  }

  @include mobile {
    padding: 0 $s-size;
    position: fixed;
  }

  .logo {
    height: inherit;
    margin-right: 2rem;
  }

  .logo img {
    width: 15rem;
    height: inherit;
    object-fit: contain;
  }

  .logo a {
    display: block;
    height: 100%;
  }

  .searchbar {
    width: 300px;
  }
}

.navigation-admin {
  height: 6rem;
  background: $white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  padding: 0.5rem $l-size;
  display: flex;
  justify-content: space-between;
}

.navigation-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  text-align: right;
}

.navigation-menu-main {
  padding-left: 0;
  margin-right: 2rem;
  flex-grow: 1;

  li {
    display: inline-block;

    a {
      padding: 10px 15px;
      font-size: 1.4rem;
      opacity: 0.5;

      &:hover {
        background: $background-color-01;
      }
    }
  }
}

.navigation-menu-item {
  display: inline-block;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.navigation-action {
  display: flex;
  align-items: center;
  margin-left: $xxl-size;
}

.navigation-menu-link {
  color: $heading-color;
  padding: $xs-size $m-size;
  text-decoration: none;
  font-size: $font-small;
  text-transform: uppercase;
  font-weight: bold;
  position: relative;

  &:hover {
    text-decoration: none;
    background: $background-color-01;
  }
}

.navigation-menu-active {
  font-weight: bold;
  opacity: 1 !important;
}

@media (max-width: 479px) {
  .navigation-menu-active {
    font-weight: bold;
    opacity: 1 !important;
    display: none;
  }

  .searchbar {
    // margin-left: -8rem;
  }
  /* … */
}
