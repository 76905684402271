label {
    font-size: $font-small;
    font-weight: bold;
    background: $border-color;
    border: 1px solid $border-color-focus;
    padding: $s-size $m-size;
    display: inline-block;
    position: relative;
    
    &:hover {
        cursor: pointer;
        background: $border-color-focus;
    }
}

.label-input {
    border: none;
    background: none;
    padding: 1rem 1.2rem;
    color: #696868;
    border: none;

    &:hover {
        background: none;
    }
}

.label-error {
    color: rgb(235, 43, 43) !important;
}