// 
// BREAKPOINTS ---------
// 
:root {
    --mobile: 43rem;
}


// Breakpoints
$mobile: 30rem;
$tablet: 50rem;
$laptop: 64rem;
$desktop: 95rem;
$l-desktop: 102rem;