.circular-progress-light {
    width: 15px;
    height: 15px;
    margin-left: $s-size;
    margin-right: $s-size;
    border-radius: 50%;
    border-top: 2px solid $white;
    border-left: 2px solid $white;
    animation: spin 2s linear infinite;
}

.circular-progress-dark {
    @extend .circular-progress-light;
    border-top: 2px solid $black;
    border-left: 2px solid $black;
}

.progress-loading {
    width: 100%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loading-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: rgba(0, 0, 0, .5);
}