.auth {
  border: 1px solid $border-color-focus;
  display: flex;
  justify-content: center;
  padding: $l-size;
  padding-top: $m-size;

  @include tablet {
    flex-direction: column;
  }

  @include mobile {
    padding: $m-size;
  }
}

.auth-content {
  width: 80rem;
  height: auto;
  margin: auto;

  @include mobile {
    width: 100%;
    margin-top: 2rem;
  }

  .loader {
    background: $background-color;
  }
}

.auth-main {
  width: 100%;

  @include tablet {
    text-align: center;

    .input-form {
      text-align: center;
    }
  }
}

.auth-provider {
  width: 51rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include tablet {
    width: 100%;
  }

  & > button {
    margin-bottom: $s-size;
  }
}

.auth-provider-button {
  width: 100%;
  font-size: 1.4rem;

  & * {
    font-size: 1.4rem;
  }

  .anticon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: $m-size;
    margin: auto;
  }
}

.auth-button {
  @include tablet {
    width: 100%;
  }
}

.provider-facebook {
  @extend .button;
  background: $color-facebook;
  color: $white;
  border: 1px solid $color-facebook;

  &:hover {
    background: $color-facebook-hover;
    border: 1px solid $color-facebook;
  }
}

.provider-github {
  @extend .button;
  background: $color-github;
  color: $white;
  border: 1px solid $color-github;

  &:hover {
    background: $color-github-hover;
    border: 1px solid $color-github;
  }
}

.provider-google {
  @extend .button;
  background: $white;
  color: $gray-01;
  border: 1px solid $border-color-focus;

  &:hover {
    background: $border-color;
    border: 1px solid $border-color;
  }

  span {
    flex-basis: 80%;
    color: $paragraph-color;
  }
}

.auth-divider {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 $xl-size;

  h6 {
    background: $background-color;
    padding: $s-size;
    z-index: 1;

    @include mobile {
      margin: 1rem 0;
    }
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    height: 30%;
    width: 1px;
    background: $border-color;

    @include tablet {
      width: 50%;
      height: 1px;
    }
  }

  &:before {
    top: 25%;

    @include mobile {
      top: 50%;
      left: 0;
    }
  }

  &:after {
    bottom: 25%;

    @include tablet {
      right: 0;
      bottom: 50%;
    }
  }
}

.auth-action {
  display: flex;
  justify-content: space-between;

  @include tablet {
    flex-direction: column;

    a {
      margin-top: $m-size;
      margin-bottom: 0;
      text-align: center;
      order: 2;
    }

    button {
      order: 1;
    }
  }
}

.auth-action-signup {
  justify-content: flex-end;
}

.auth-message {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $s-size;
  margin: auto;
  background: $background-color-01;
  border: 1px solid $border-color-focus;
  border-top: none;
}

.auth-info {
  margin-right: $l-size;
}

.forgot_password {
  width: 50%;
  margin: auto;

  @include mobile {
    width: 100%;
    padding: $s-size;
  }
}

.auth-success {
  @extend .toast-success;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
