@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes slide-up {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }
  
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

@keyframes scale {
    0% {
        transform: translate(-50%, -50%) scale(0);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}

@keyframes fullWidth {
    100% { width: 100%; }
}

@keyframes slide-down {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}